.input_container {
  background-color: rgb(242, 242, 242);
  border-radius: 6px;
  padding: 12px 18px;
  margin: 0.3rem auto;
  width: 60%;
}

.button_container {
  margin: 0.5rem auto;
  width: calc(60% + 36px);
  display: flex;
  justify-content: flex-end;
}
