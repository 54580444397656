@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");

/* * {
  outline: 1px solid black;
} */

html {
  font-family: "Open Sans", sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: normal;
  /* line-height: 1.5; */
}

body {
  padding: 0;
  border: 0;
  margin: 5.75rem auto 3rem auto;
  align-self: center;
  width: 60%;
  min-width: 900px;
  max-width: 1350px;
}

@media (max-width: 1880px) {
  html {
    font-size: 12px;
  }
  body {
    margin: 6.75rem auto 2rem auto;
    width: 75%;
  }
}

@media (max-width: 1000px) {
  body {
    margin: 6.5rem 1.5rem 1rem 1.5rem;
    width: 60% !important;
    max-width: 800 !important;
  }
}

h1 {
  font-size: 1.3rem;
}

h2 {
  font-size: 1.25rem;
}

h3 {
  font-size: 1rem;
}

p,
button {
  font-size: 1rem;
}

h1,
h2,
h3,
p,
ul {
  margin: 0 0 0.75rem 0;
}

p:last-child,
p:only-child {
  margin: 0;
}

table,
th,
td {
  border-collapse: collapse;
  margin: 0 0 0.75rem 0;
  font-size: 1rem;
  border: 1px solid;
  padding: 0.5rem;
}

a {
  color: hsl(174, 75%, 36%);
}
