.pagebutton {
  font: inherit;
  cursor: pointer;
  background-color: rgba(6, 3, 141, 0.95);
  color: rgb(255, 255, 255);
  padding: 0.5rem 1.5rem;
  border: 2.5px solid rgba(6, 3, 141, 0.95);
  border-radius: 4px;
  font-size: 1.2rem;
  font-weight: bold;
}

.pagebutton:hover {
  color: rgb(255, 255, 255);
  border: 2.5px solid rgba(6, 3, 141, 0.85);
  background-color: rgba(6, 3, 141, 0.85);
}
