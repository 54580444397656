.main_container {
  display: flex;
  flex-direction: row;
  margin: 0;
  margin: 1.25rem 0;
}

.chatIcon {
  height: 65px;
  padding-top: 5px;
  padding-right: 7px;
}

.textbox {
  display: flex;
  flex-direction: column;
  background-color: #f2f2f2;
  border-radius: 22px 0 22px 22px;
  padding: 0.75rem 1.25rem;
  flex: 6;
}

.textbox h3 {
  margin: 0.25rem 0 0.25rem;
}

.textbox h3:nth-of-type(2) {
  margin-top: 1.25rem;
}

.textbox h2 {
  margin: 0;
}

.textbox p {
  margin: 0;
}
