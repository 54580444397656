.SurveyContainer {
  border: 2px black solid;
  padding: 0.75rem;
  margin: 1rem 0.25rem 1rem 0.25rem;
  background-color: hsl(174, 75%, 36%, 0.25);
  border-radius: 5px;
}

.SurveyContainer a {
  color: hsl(174, 75%, 36%);
  font-weight: bold;
}
