.mainContainer {
  display: flex;
  flex-direction: row;
  margin: 1.3rem 0;
}

.chatIcon {
  height: 65px;
  padding-top: 5px;
  padding-right: 7px;
}

.textbox {
  display: flex;
  flex-direction: column;
  background-color: #f2f2f2;
  border-radius: 22px 0 22px 22px;
  padding: 10px 18px;
  font-size: 1rem;
  flex: 12;
}

.questions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 2;
}

.button {
  background-color: transparent;
  border: 2px solid hsl(174, 75%, 36%);
  border-radius: 22px 0 22px 22px;
  padding: 6px 16px;
  margin-top: 0.5rem;
  color: hsl(174, 75%, 36%);
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  text-decoration: none;
  text-align: left;
}

.button:hover {
  color: hsl(205, 58%, 59%);
  border-color: hsl(205, 58%, 59%);
  text-decoration: none;
}

.button + .button {
  margin-left: 0.3rem;
}

.active {
  background-color: hsl(205, 58%, 59%);
  color: white;
  border-color: hsl(205, 58%, 59%);
}

.active:hover {
  background-color: hsl(205, 58%, 59%);
  color: white;
  border-color: hsl(205, 58%, 59%);
  text-decoration: none;
  cursor: default;
}
