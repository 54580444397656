.main_container {
  border-radius: 6px;
  border: 1.5px solid rgb(0, 0, 0);
  padding: 10px 15px;
  flex: 2;
  height: fit-container;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-right: 0 !important;
}

.help_button {
  display: block;
  border: 2px solid rgba(0, 0, 0, 1);
  color: rgba(0, 0, 0, 1);
  border-radius: 6px;
  background-color: transparent;
  font-size: 0.8rem;
  font-weight: 600;
  height: fit-content;
}

.help_button:hover {
  cursor: pointer;
  border: 2px solid hsl(0, 0%, 0%, 0.6);
  color: hsl(0, 0%, 0%, 0.6);
}

.radio_container {
  display: flex;
  justify-content: space-between;
}

.radio_container input[type="radio"] {
  opacity: 0;
  position: fixed;
}

.radio_container1 {
  display: flex;
  justify-content: space-between;
}

.radio_container1 input[type="radio"] {
  opacity: 0;
  position: fixed;
}

.radio_container label {
  display: inline-block;
  padding: 0.25rem;
  color: hsl(0, 0%, 66%);
  border: 2px solid hsl(0, 0%, 66%);
  background-color: hsla(0, 0%, 66%, 0.2);
  border-radius: 4px;
  font-weight: 600;
  flex: 1;
  text-align: center;
  font-size: 1.2rem;
}

.radio_container label:not(:nth-of-type(1)) {
  margin-left: -0.1rem;
}

.radio_container1 label:not(:nth-of-type(1)) {
  margin-left: 0.4rem;
}

.radio_container1 label {
  display: inline-block;
  padding: 0.25rem;
  color: hsl(0, 0%, 66%);
  border: 2px solid hsl(0, 0%, 66%);
  background-color: hsla(0, 0%, 66%, 0.2);
  border-radius: 4px;
  font-weight: 600;
  flex: 1;
  text-align: center;
  font-size: 1.2rem;
}

.radio_container input[type="radio"]:checked + label {
  background-color: hsla(174, 75%, 36%, 0.2);
  border: 2px solid hsl(174, 75%, 36%);
  color: hsl(174, 75%, 36%);
}

.radio_container label:hover {
  color: hsl(205, 58%, 59%);
  border-color: hsl(205, 58%, 59%);
  text-decoration: none;
  background-color: hsla(205, 58%, 59%, 0.2);
}

.radio_container input[type="radio"]:not(:checked) + label:hover {
  cursor: pointer;
}

.radio_container input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  width: 70%;
  height: 2.07rem;
  border: 2px solid black;
  overflow: hidden;
  border-radius: 4px;
  outline: none;
  margin: 0;
  margin-right: 0.4rem;
  cursor: pointer;
}

.radio_container input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 0.5rem;
  height: 2.1rem;
  box-shadow: -500px 0 0 500px hsla(174, 75%, 36%, 0.5);
  background: rgba(0, 0, 0, 1);
  opacity: 1;
}

input[type="range"]:hover::-webkit-slider-thumb {
  opacity: 1;
}

.radio_container p {
  display: inline-block;
  padding: 0.25rem;
  color: rgba(0, 0, 0, 1);
  border: 2px solid rgba(0, 0, 0, 1);
  background-color: rgba(255, 255, 255, 1);
  border-radius: 4px;
  font-weight: 600;
  flex: 1;
  text-align: center;
  font-size: 1.2rem;
}

.radio_container1 input[type="radio"]:checked + label {
  background-color: hsla(174, 75%, 36%, 0.2);
  border: 2px solid hsl(174, 75%, 36%);
  color: hsl(174, 75%, 36%);
}

.radio_container1 label:hover {
  color: hsl(205, 58%, 59%);
  border-color: hsl(205, 58%, 59%);
  text-decoration: none;
  background-color: hsla(205, 58%, 59%, 0.2);
}

.radio_container1 input[type="radio"]:not(:checked) + label:hover {
  cursor: pointer;
}

.radio_container1 input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  width: 70%;
  height: 2.07rem;
  border: 2px solid black;
  overflow: hidden;
  border-radius: 4px;
  outline: none;
  margin: 0;
  margin-right: 0.4rem;
  cursor: pointer;
}

.radio_container1 input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 0.5rem;
  height: 2.1rem;
  box-shadow: -500px 0 0 500px hsl(205, 58%, 59%);

  background: rgba(0, 0, 0, 1);
  opacity: 0.65;
}

input[type="range"]:hover::-webkit-slider-thumb {
  opacity: 1;
}

.radio_container1 p {
  display: inline-block;
  padding: 0.25rem;
  color: rgba(0, 0, 0, 1);
  border: 2px solid rgba(0, 0, 0, 1);
  background-color: rgba(255, 255, 255, 1);
  border-radius: 4px;
  font-weight: 600;
  flex: 1;
  text-align: center;
  font-size: 1.2rem;
}
