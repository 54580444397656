.modal {
  position: fixed;
  bottom: 2%;
  left: 1.5rem;
  border: 1.5px solid hsla(0, 0%, 0%, 1);
  border-radius: 6px;
  background-color: hsl(0, 0%, 100%);
  padding: 1rem;
  text-align: center;
  z-index: 99;
  display: flex;
  flex-direction: column;
  width: 10rem;
  animation-name: spinAndMove, bob;
  animation-duration: 0.5s, 0.2s;
  animation-delay: 0s, 0.5s;
  animation-timing-function: ease-out, ease-in-out;
  animation-iteration-count: 1, infinite;
}

.modal.modal_hovered {
  transform: translate(0, 0) scale(1) rotate(0deg);
  animation: none;
}

.video {
  height: 100%;
  width: 100%;
  border: 2px solid black;
}

.survey_btn {
  font: inherit;
  padding: 0.5rem 1.5rem;
  cursor: pointer;
  border-radius: 4px;
  background-color: hsl(174, 75%, 36%);
  color: hsl(0, 0%, 100%);
  border: 1px solid hsl(174, 75%, 36%);
}

.survey_btn:hover {
  background-color: hsl(174, 75%, 40%);
  border-color: hsl(174, 75%, 40%);
}

.close_btn {
  background: none !important;
  border: none;
  padding: 0 !important;
  color: hsl(174, 75%, 36%);
  text-decoration: underline;
  cursor: pointer;
  margin-top: 0.5rem;
}

@keyframes spinAndMove {
  0% {
    transform: translate(700px, -700px) rotate(0deg) scale(3);
  }
  100% {
    transform: translate(0, 0) rotate(1080deg) scale(1);
  }
}

@keyframes bob {
  0% {
    transform: translateY(0px);
  }
  25% {
    transform: translateY(-2px);
  }
  50% {
    transform: translateY(0px);
  }
  75% {
    transform: translateY(2px);
  }
  100% {
    transform: translateY(0px);
  }
}
