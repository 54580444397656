.background {
  position: fixed;
  z-index: 3;
  background-color: rgba(0, 0, 0, 0.75);
  width: 800vw;
  height: 1000vh;
  top: 0;
  left: 0;
}

.modal {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  background-color: rgb(255, 255, 255);
  padding: 1.2rem;
  text-align: center;
  width: 40rem;
  position: fixed;
  z-index: 10;
  top: 7vh;
  left: calc(50% - 20rem);
}

.btn_main {
  font: inherit;
  padding: 0.5rem 1.5rem;
  border-radius: 4px;
  margin: 1.2rem;
  margin-bottom: 0.5rem;
  font-weight: 500;
}

.btn_agreed {
  cursor: pointer;
  background-color: rgba(6, 3, 141, 0.95);
  color: rgb(255, 255, 255);
  border: 1px solid rgba(6, 3, 141, 0.95);
}

.btn_agreed:hover {
  background-color: rgba(6, 3, 141, 0.85);
  border-color: rgba(6, 3, 141, 0.85);
}

.btn_reject {
  background-color: rgb(210, 210, 210);
  color: rgb(100, 100, 100);
  border: 1px solid rgb(210, 210, 210);
}

.checkbox {
  accent-color: rgba(6, 3, 141, 0.95);
  height: 1rem;
  width: 1rem;
  vertical-align: middle;
  margin: 0;
}

.checkbox:hover {
  cursor: pointer;
  background-color: rgba(6, 3, 141, 0.85);
}

.modal h2 {
  margin: 0.5rem;
}

.modal p,
.modal table {
  text-align: justify;
  margin: 0.75rem 0.75rem;
}

.modal table {
  margin: auto;
}

.modal label:hover {
  cursor: pointer;
}
